import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { deleteUserFile, getUserById, resetPassword, } from '../services/users';
import { dotSeparateNumber } from '../utils/tools';
import { Dropdown } from 'react-bootstrap';
import { cancelList as cancelTransactionList, getHistory } from '../services/transactions';
import { cancelListFromUserDetails as cancelBookings, bookingStatusChange, listBookingFromUserDetail } from '../services/booking';
import ReactTable from 'react-table-6';
import DateRangeFilter from '../components/assets/date-range-table';
import { getAdminByName } from '../services/admins';
import AsyncSelect from 'react-select/async';
import { getAssetsByName } from '../services/assets';
import withReactContent from 'sweetalert2-react-content';
import AddUserFile from '../components/AddUserFile';
import AddUserExpense from '../components/AddUserExpense';
import AddUserImage from '../components/AddUserImage';
import { cancel as cancelExpenses, listExpenses } from '../services/expenses';

const swal = withReactContent(Swal);

const status = {
    Reserved: 'Reservado',
    Confirmed: 'Confirmado',
    Cancelled: 'Cancelado',
    'No Show': 'No se presentó',
    "Check In": 'Salida registrada',
};

const customStyles = {
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
    option: (provided, state) => ({
        ...provided,
        color: state.isFocused ? 'white' : 'black',
        backgroundColor: state.isFocused ? 'blue' : 'white',
    }),
};

const types = {
    buy: 'Compra',
    refund: 'Devolución',
    booking: 'Reserva',
};

const User = (props) => {
    const [user, setUser] = useState({});
    const { id } = useParams();
    const navigate = useNavigate();
    // transactions
    const [transactions, setTransactions] = useState([]);
    const [loadingTransactions, setLoadingTransactions] = useState(true);
    const [transactionsPages, setTransactionsPages] = useState(0);
    const [timestamp, setStart_date] = useState({ startDate: null, endDate: null });
    const [adminOpt, setAdminOpt] = useState([]);
    const timestampPick = useRef();
    // expenses
    const [expenses, setExpenses] = useState([]);
    const [expensesPages, setExpensesPages] = useState(0);
    const [expensesModal, setExpensesModal] = useState(false);
    const [loadingExpenses, setLoadingExpenses] = useState(true);
    const expensesRef = useRef();
    // bookings
    const [asset, setAsset] = useState(null);
    const bookingTable = useRef();
    const [start_time, setStart_time] = useState({ startDate: null, endDate: null });
    const [end_time, setEnd_time] = useState({ startDate: null, endDate: null });
    const [bookings, setBookings] = useState([]);
    const [loadingBookings, setLoadingBookings] = useState(true);
    const [bookingPages, setBookingsPages] = useState(0);
    const start_timePick = useRef();
    const end_timePick = useRef();
    // profile image
    const [imageModal, setImageModal] = useState(false);

    const [modal, setModal] = useState(false);

    const applyStartDateFilter = async (state) => {
        const { start, end } = start_timePick.current.state;

        if (state) {
            setStart_time({ startDate: state.start, endDate: state.end });
        } else {
            setStart_time({ startDate: start, endDate: end });
        }
    };

    const applyEndDateFilter = async (state) => {
        const { start, end } = end_timePick.current.state;

        if (state) {
            setEnd_time({ startDate: state.start, endDate: state.end });
        } else {
            setEnd_time({ startDate: start, endDate: end });
        }
    };

    const prepareBookingFilters = (arr = []) => {
        const filters = arr.filter(a => 'end_time' !== a.id || 'start_time' !== a.id);

        if (end_time.startDate && end_time.endDate) {
            filters.push({ id: 'end_time', value: { from: end_time.startDate, to: end_time.endDate } });
        }

        if (start_time.startDate && start_time.endDate) {
            filters.push({ id: 'start_time', value: { from: start_time.startDate, to: start_time.endDate } });
        }

        if (asset?.value) {
            filters.push({ id: 'asset', value: asset.value });
        }

        return filters;
    };

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const res = await getAdminByName();
                if (res?.data) {
                    setAdminOpt(res.data);
                }
            } catch(err) {
            }
        };

        fetchOptions();
    }, []);

    useEffect(() => {
        const searchUser = async (id) => {
            try {
                const res = await getUserById(id);

                if (!res || !res.success) {
                    throw new Error();
                }

                setUser(res.data);
            } catch{
                return Swal.fire({
                    title: "Hubo un problema",
                    text: 'Usuario no encontrado',
                    icon: "error",
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: "btn btn-danger",
                    }
                }).then(() => navigate('/users'));
            }
        };
        searchUser(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);


    const handlePassReset = async () => {
        Swal.fire({
            title: "Está seguro?",
            text: "Está por cambiar la contraseña a un usuario",
            icon: "info",
            buttonsStyling: false,
            showCancelButton: true,
            reverseButtons: true,
            customClass: {
                confirmButton: "btn btn-info ms-2",
                cancelButton: "btn btn-secondary "
            }
        }).then(async ({ isConfirmed }) => {
            if (!isConfirmed) { return; }
            try {
                const res = await resetPassword(id);

                if (!res.success) { throw new Error(); }

                return Swal.fire({
                    title: "Contraseña reseteada",
                    text: "La contraseña del usuario ha sido restablecida con éxito",
                    icon: "success",
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: "btn btn-success",
                    }
                });
            } catch(err) {
                return Swal.fire({
                    title: "Error",
                    text: "No se pudo restablecer la contraseña del usuario",
                    icon: "error",
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: "btn btn-danger",
                    }
                });
            }
        });
    };

    const applyDateFilter = async (state) => {
        const { start, end } = timestampPick.current.state;

        if (state) {
            setStart_date({ startDate: state.start, endDate: state.end });
        } else {
            setStart_date({ startDate: start, endDate: end });
        }
    };

    const prepareTransactionFilters = (arr = []) => {
        const filters = arr.filter(a => 'timestamp' !== a.id);

        if (timestamp.startDate && timestamp.endDate) {
            filters.push({ id: 'timestamp', value: { from: timestamp.startDate, to: timestamp.endDate } });
        }
        filters.push({ id: 'user', value: id });
        return filters;
    };

    const fetchExpenses = async (state) => {
        setLoadingExpenses(true);

        if (cancelExpenses) {
            cancelExpenses();
        }

        const options = {
            sort: state.sorted.length > 0 ? state.sorted[0].id : 'createdAt',
            direction: state.sorted.length > 0 ? state.sorted[0].desc ? 'desc' : 'asc' : 'desc',
            filters: [...state.filtered],
            results: state.pageSize,
            offset: (state.page + 1) * state.pageSize - state.pageSize
        };

        if (!options.filters.some(e => e.id === 'user')) {
            options.filters.push({ id: 'user', value: id });
        }

        try {
            const res = await listExpenses(options);
            if (res?.success) {
                setExpenses(res.data);
                setExpensesPages(res.pages);
            }
        } catch(err) {
            return Swal.fire({
                title: "Hubo un problema",
                text: "Intente nuevamente",
                icon: "error",
                buttonsStyling: false,
                customClass: {
                    confirmButton: "btn btn-danger",
                }
            });
        } finally {
            setLoadingBookings(false);
        }
    };

    const fetchBookings = async (state) => {
        setLoadingBookings(true);

        if (cancelBookings) {
            cancelBookings();
        }

        const options = {
            sort: state.sorted.length > 0 ? state.sorted[0].id : 'createdAt',
            direction: state.sorted.length > 0 ? state.sorted[0].desc ? 'desc' : 'asc' : 'desc',
            filters: prepareBookingFilters(state.filtered),
            results: state.pageSize,
            offset: (state.page + 1) * state.pageSize - state.pageSize
        };

        options.filters.push({ id: 'user', value: id });

        try {
            const res = await listBookingFromUserDetail(options);
            if (res?.success) {
                setBookings(res.data);
                setBookingsPages(res.pages);
            }
        } catch(err) {
            return Swal.fire({
                title: "Hubo un problema",
                text: "Intente nuevamente",
                icon: "error",
                buttonsStyling: false,
                customClass: {
                    confirmButton: "btn btn-danger",
                }
            });
        } finally {
            setLoadingBookings(false);
        }
    };

    const fetchTransactions = async (state) => {
        setLoadingTransactions(true);

        if (cancelTransactionList) {
            cancelTransactionList();
        }

        const options = {
            sort: state.sorted.length > 0 ? state.sorted[0].id : 'createdAt',
            direction: state.sorted.length > 0 ? state.sorted[0].desc ? 'desc' : 'asc' : 'desc',
            filters: prepareTransactionFilters(state.filtered),
            results: state.pageSize,
            offset: (state.page + 1) * state.pageSize - state.pageSize
        };

        try {
            const res = await getHistory(options);
            if (res?.data) {
                setTransactions(res.data);
                setTransactionsPages(res.pages);
            }
        } catch(err) {
            return Swal.fire({
                title: "Hubo un problema",
                text: "Intente nuevamente",
                icon: "error",
                buttonsStyling: false,
                customClass: {
                    confirmButton: "btn btn-danger",
                }
            });
        } finally {
            setLoadingTransactions(false);
        }
    };

    const fetchAssets = async (name) => {
        try {
            const res = await getAssetsByName(name || []);
            return res?.data?.map(e => ({ label: e.name, value: e._id })) || [];
        } catch(error) {
            return [];
        }
    };

    const handleStatusChange = async (id, new_status) => {
        swal.fire({
            title: '¿Estás seguro de cambiar el estado de la reserva?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, cambiar'
        }).then(async ({ isConfirmed }) => {
            if (!isConfirmed) { return; }
            try {
                const res = await bookingStatusChange(id, new_status);

                if (!res?.success) { throw new Error(); }

                Swal.fire({
                    title: 'Cambio realizado',
                    text: 'El estado de la reserva se ha actualizado correctamente',
                    icon: 'success',
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: "btn btn-primary",
                    }
                });
                bookingTable.current.fireFetchData();
            } catch(error) {
                Swal.fire({
                    title: 'Hubo un problema',
                    text: 'No pudimos realizar la operación',
                    icon: 'error',
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: "btn btn-danger",
                    }
                });
            }
        });
    };

    const updateUserFiles = (files) => setUser(s => ({ ...s, files: s.files ? [...s.files, ...files] : files }));

    const handleDeleteFile = async (url, id) => {
        Swal.fire({
            title: 'Elminando archivo',
            text: 'Está seguro que desea eliminar el archivo?',
            icon: 'warning',
            buttonsStyling: false,
            showCancelButton: true,
            reverseButtons: true,
            customClass: {
                confirmButton: "btn btn-warning ms-3",
                cancelButton: "btn btn-primary",
            }
        }).then(async ({ isConfirmed }) => {
            if (!isConfirmed) { return; }

            const res = await deleteUserFile(url, id);

            if (!res?.success) { throw new Error(); }
            setUser(s => ({ ...s, files: s.files.filter(e => e.url !== url) }));

            Swal.fire({
                title: 'Archivo eliminado',
                text: 'El archivo se ha eliminado correctamente',
                icon: 'success',
                buttonsStyling: false,
                customClass: {
                    confirmButton: "btn btn-primary",
                }
            });
        }).catch(_err => {
            Swal.fire({
                title: 'Hubo un problema',
                text: 'No pudimos realizar la operación',
                icon: 'error',
                buttonsStyling: false,
                customClass: {
                    confirmButton: "btn btn-danger",
                }
            });
        });
    };

    const updateUserImage = async url => {
        setUser(s => ({ ...s, profilepic: url }));
    };

    return (
        <div className="row small-spacing">
            <div className="col-xl-4 col-lg-5 col-md-12 col-xs-12">
                <div className="box-content bordered primary margin-bottom-20">
                    <div className="profile-avatar">
                        <button type="button" style={{ zIndex: 9999999999, right: 10 }} className="position-absolute mt-2 me-4 btn btn-primary btn-sm text-white rounded" onClick={() => setImageModal(true)} >
                            <i color={'white'} size={20} className='fa-solid fa-user-pen' />
                        </button>
                        {
                            user?.profilepic
                                ?
                                <img src={user.profilepic} alt={user?.name} />
                                :
                                <div className="user-pic-placeholder bg-gradient-success d-flex">
                                    <i className="fas fa-user-astronaut justify-content-center align-self-center" />
                                </div>
                        }
                        <Dropdown className='dropdown'>
                            <Dropdown.Toggle className="btn btn-icon btn-icon-left btn-block btn-primary btn-inbox w-100" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="ico fas fa-bars" /> Acciones</Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu sub-menu" aria-labelledby="dropdownMenuButton">
                                <Dropdown.Item onClick={handlePassReset} className="dropdown-item">Resetear contraseña</Dropdown.Item>
                                <Dropdown.Item onClick={() => setModal(true)} className="dropdown-item">Añadir archivo</Dropdown.Item>
                                <Dropdown.Item onClick={() => setExpensesModal(true)} className="dropdown-item">Cargo adicional</Dropdown.Item>
                                <Dropdown.Item onClick={() => window.open('/users/edit/' + id + '?profile=true', '_self')} className="dropdown-item">Editar perfil</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <h3 className="mt-3"><strong className="capitalize">{user?.name}</strong></h3>
                        <h4 className="mb-3">{user?.brand}</h4>
                    </div>
                    <table className="table table-hover no-margin">
                        <tbody>
                            <tr>
                                <td>Estado</td>
                                <td>
                                    {user?.is_active
                                        ? <span className="notice notice-blue">Activo</span>
                                        : <span className="notice notice-danger">No Activo</span>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>Fecha de Registro</td>
                                <td>{user?.register_date ? new Date(user?.register_date).toLocaleDateString(props.locale, { timeZone: props.timeZone }) : 'Waiting for data'}</td>
                            </tr>
                            <tr>
                                <td>Nombre</td>
                                <td>{user?.first_name}</td>
                            </tr>
                            <tr>
                                <td>Apellido</td>
                                <td>{user?.last_name}</td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td>{user?.email}</td>
                            </tr>
                            <tr>
                                <td>DNI</td>
                                <td>{user?.national_identity}</td>
                            </tr>
                            <tr>
                                <td>Teléfono</td>
                                <td>{user?.phone_number || '-'}</td>
                            </tr>
                            <tr>
                                <td>Créditos</td>
                                <td>{dotSeparateNumber(user?.credits)}</td>
                            </tr>
                            <tr>
                                <td>Plan</td>
                                <td>{user?.plan?.name || '-'}</td>
                            </tr>
                            <tr>
                                <td>Vencimiendo cuota</td>
                                <td>{user?.plan_expiration ? new Date(user.plan_expiration).toLocaleDateString(props.locale, { timeZone: props.timeZone, hour12: false }) : '-'}</td>
                            </tr>
                            <tr>
                                <td>Licencia</td>
                                <td>{user?.license || '-'}</td>
                            </tr>
                            <tr>
                                <td>Vencimiendo licencia</td>
                                <td>{user?.license_expiration_date ? new Date(user.license_expiration_date).toLocaleDateString(props.locale, { timeZone: props.timeZone, hour12: false }) : '-'}</td>
                            </tr>
                            <tr>
                                <td>Pagaré (USD)</td>
                                <td>{user?.promissory_note || '-'}</td>
                            </tr>
                            <tr>
                                <td>Depósito de garantía (USD)</td>
                                <td>{user?.security_deposit || '-'}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="col-xl-8 col-lg-7 col-md-12 col-xs-12">
                <div className="row">
                    <div className="col-md-12 col-xs-12">
                        <div className="box-content card">
                            <div className='box-title d-flex justify-content-between'>
                                <h4 className="box-title"><i className="fa fa-star-half-alt ico"></i> Transacciones</h4>
                                <button type="button" className="btn btn-success btn-md text-white" onClick={() => window.open('/transactions/create', '_self')} >
                                    <i color={'white'} size={20} className='fa-solid fa-file-circle-plus' />
                                </button>
                            </div>
                            <ReactTable
                                data={transactions}
                                columns={[
                                    {
                                        id: 'admin',
                                        Header: "Administrador",
                                        accessor: (d) => d?.admin?.name,
                                        filterable: true,
                                        Filter: ({ filter, onChange }) =>
                                            <select
                                                onChange={event => onChange(event.target.value)}
                                                style={{ width: "100%" }}
                                                value={filter ? filter.value : ""}
                                            >
                                                <option value="all">Todo</option>
                                                {adminOpt.map(e => <option key={e._id} value={e._id}>{e.name}</option>)}
                                            </select>,
                                    },
                                    {
                                        id: 'type',
                                        Header: "Tipo",
                                        accessor: (d) => types[d.type],
                                        Filter: ({ filter, onChange }) =>
                                            <select
                                                onChange={event => onChange(event.target.value)}
                                                style={{ width: "100%" }}
                                                value={filter ? filter.value : ""}
                                            >
                                                <option value="all">Todo</option>
                                                <option value="buy">Compra</option>
                                                <option value="refund">Devolución</option>
                                                <option value="booking">Reserva</option>
                                            </select>,
                                        filterable: true,
                                    },
                                    {
                                        id: 'timestamp',
                                        Header: "Fecha",
                                        filterable: true,
                                        accessor: (d) => new Date(d.timestamp).toLocaleDateString(props.locale, { timeZone: props.timeZone, hour12: false }),
                                        Filter: ({ filter, onChange }) => (<DateRangeFilter applyFilter={(e) => { applyDateFilter(e); onChange(e); }}
                                            selectedEndDate={timestamp.endDate}
                                            selectedStartDate={timestamp.startDate}
                                            filter={filter}
                                            onChange={onChange}
                                            ref={timestampPick} />)
                                    },
                                    {
                                        id: 'plan',
                                        Header: "Plan",
                                        accessor: (d) => d?.plan?.name,
                                    },
                                    {
                                        id: 'balance',
                                        Header: "Saldo",
                                        accessor: (d) => dotSeparateNumber(d.balance),
                                    },
                                    {
                                        id: 'cost',
                                        Header: "Costo (USD)",
                                        accessor: (d) => d.type !== 'buy' ? '-' : dotSeparateNumber(d.cost),
                                    },
                                    {
                                        id: 'credits',
                                        Header: "Creditos",
                                        accessor: (d) => dotSeparateNumber(d.credits),
                                    },
                                    {
                                        id: 'booking_name',
                                        Header: "Reserva",
                                        accessor: (d) => d.booking_name,
                                        filterable: false,
                                    },
                                    {
                                        id: '_id',
                                        Header: '',
                                        show: false,
                                        filterable: false,
                                        className: 'actions-cell',
                                        accessor: (d) => (
                                            <Dropdown>
                                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="btn btn-xs btn-circle btn-default custom-dropdown-toggle">
                                                    <i className="ico fas fa-ellipsis-v text-dark" />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item href={'transactions/edit/' + d._id}>Editar</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        ),
                                        width: 50
                                    }
                                ]}
                                pages={transactionsPages}
                                defaultPageSize={10}
                                loading={loadingTransactions}
                                showPagination={true}
                                showPaginationTop={false}
                                showPaginationBottom={true}
                                showPageJump={true}
                                resizable={true}
                                sortable={true}
                                manual
                                onFetchData={fetchTransactions}
                                onSortedChange={cancelTransactionList}
                                onFilteredChange={cancelTransactionList}
                                className="-striped -highlight"
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-xs-12">
                        <div className="box-content card">
                            <div className='box-title d-flex justify-content-between'>
                                <h4 className="box-title"><i className="fa fa-star-half-alt ico"></i> Reservas</h4>
                                <button type="button" className="btn btn-success btn-md text-white" onClick={() => window.open('/bookings/create', '_self')} >
                                    <i color={'white'} className='fa-solid fa-file-circle-plus' />
                                </button>
                            </div>
                            <ReactTable
                                data={bookings || []}
                                columns={[
                                    {
                                        id: 'asset',
                                        Header: 'Activo',
                                        accessor: (d) => d?.asset?.name,
                                        filterable: true,
                                        Filter: ({ onChange }) =>
                                            <AsyncSelect
                                                cacheOptions
                                                isSearchable
                                                isClearable={true}
                                                loadOptions={fetchAssets}
                                                value={asset || null}
                                                onChange={v => { setAsset(v); onChange(v); }}
                                                noOptionsMessage={() => 'Sin resultados'}
                                                styles={customStyles}
                                                menuPosition="fixed"
                                                classNames={{
                                                    option: (state) => (
                                                        `${state.isFocused ? 'bg-info' : 'text-dark'}`
                                                    )
                                                }} />
                                    },
                                    {
                                        id: 'start_time',
                                        Header: "Inicio",
                                        accessor: (d) => new Date(d.start_time).toLocaleDateString(props.locale, { timeZone: props.timeZone, hour12: false }),
                                        filterable: true,
                                        Filter: ({ filter, onChange }) => (<DateRangeFilter applyFilter={(e) => { applyStartDateFilter(e); onChange(e); }}
                                            selectedEndDate={start_time.endDate}
                                            selectedStartDate={start_time.startDate}
                                            filter={filter}
                                            onChange={onChange}
                                            ref={start_timePick} />)
                                    },
                                    {
                                        id: 'days',
                                        Header: "Días",
                                        accessor: (d) => d.days,
                                        filterable: true,
                                    },
                                    {
                                        id: 'end_time',
                                        Header: "Devolución",
                                        accessor: (d) => new Date(d.end_time).toLocaleDateString(props.locale, { timeZone: props.timeZone, hour12: false }),
                                        filterable: true,
                                        Filter: ({ filter, onChange }) => (<DateRangeFilter applyFilter={(e) => { applyEndDateFilter(e); onChange(e); }}
                                            selectedEndDate={end_time.endDate}
                                            selectedStartDate={end_time.startDate}
                                            filter={filter}
                                            onChange={onChange}
                                            ref={end_timePick} />)
                                    },
                                    {
                                        id: 'credit_cost',
                                        Header: "Creditos",
                                        accessor: (d) => <span>{dotSeparateNumber(d.credit_cost)}</span>,
                                        filterable: true
                                    },
                                    {
                                        id: 'costs',
                                        Header: "Costo/día",
                                        accessor: (d) => d.costs?.map((e, i) => i !== d.costs.length - 1 ? dotSeparateNumber(e) + ' - ' : dotSeparateNumber(e)),
                                        filterable: false,
                                    },
                                    {
                                        id: 'status',
                                        Header: "Estado",
                                        accessor: (d) => status[d.status],
                                        Filter: ({ filter, onChange }) =>
                                            <select
                                                onChange={event => onChange(event.target.value)}
                                                style={{ width: "100%" }}
                                                value={filter ? filter.value : ""}
                                            >
                                                <option value="all">Todo</option>
                                                {Object.keys(status).map(e => <option key={e + 'status_option'} value={e}>{status[e]}</option>)}
                                            </select>,
                                        filterable: true,
                                    },
                                    {
                                        id: '_id',
                                        Header: '',
                                        filterable: false,
                                        className: 'actions-cell',
                                        accessor: (d) => (
                                            (d.status !== 'Cancelled' && d.status !== 'Completed') ?
                                                <Dropdown>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item href={'bookings/edit/' + d._id}>{(d.status !== 'Cancelled' && d.status !== 'Completed') ? 'Editar' : 'Ver'}</Dropdown.Item>
                                                        <hr />
                                                        <Dropdown.Item disabled={true}>Cambiar estado</Dropdown.Item>
                                                        {!['Cancelled', 'Completed'].includes(d.status) && <Dropdown.Item onClick={() => handleStatusChange(d._id, 'Completed')}>{status.Completed}</Dropdown.Item>}
                                                        {!['Cancelled', 'Completed'].includes(d.status) && <Dropdown.Item onClick={() => handleStatusChange(d._id, 'Cancelled')}>{status.Cancelled}</Dropdown.Item>}
                                                        {!['Reserved', 'Cancelled', 'Completed'].includes(d.status) && <Dropdown.Item onClick={() => handleStatusChange(d._id, 'Reserved')}>{status.Reserved}</Dropdown.Item>}
                                                        {!['Check In', 'Cancelled', 'Completed'].includes(d.status) && <Dropdown.Item onClick={() => handleStatusChange(d._id, 'Check In')}>{status['Check In']}</Dropdown.Item>}
                                                        {!['No Show', 'Cancelled', 'Completed'].includes(d.status) && <Dropdown.Item onClick={() => handleStatusChange(d._id, 'No Show')}>{status['No Show']}</Dropdown.Item>}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                : null
                                        ),
                                        width: 50
                                    }
                                ]}
                                pages={bookingPages}
                                defaultPageSize={10}
                                loading={loadingBookings}
                                showPagination={true}
                                showPaginationTop={false}
                                showPaginationBottom={true}
                                showPageJump={true}
                                resizable={true}
                                sortable={true}
                                manual
                                onFetchData={fetchBookings}
                                onSortedChange={cancelBookings}
                                onFilteredChange={cancelBookings}
                                className="-striped -highlight"
                                ref={bookingTable}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-xs-12">
                        <div className="box-content card">
                            <div className='box-title d-flex justify-content-between'>
                                <h4 className="box-title"><i className="fa fa-star-half-alt ico"></i> Archivos</h4>
                                <button type="button" className="btn btn-success btn-md text-white" onClick={() => setModal(true)} >
                                    <i color={'white'} size={20} className='fa-solid fa-file-circle-plus' />
                                </button>
                            </div>
                            <ReactTable
                                data={user?.files || []}
                                columns={[
                                    {
                                        id: 'name',
                                        Header: "Nombre",
                                        accessor: (d) => d.name,
                                        filterable: true
                                    },
                                    {
                                        id: 'admin.name',
                                        Header: "Admin",
                                        accessor: (d) => d.admin?.name,
                                        filterable: false,
                                    },
                                    {
                                        id: 'type',
                                        Header: "Formato",
                                        accessor: (d) => d.type,
                                        Filter: ({ filter, onChange }) =>
                                            <select
                                                onChange={event => onChange(event.target.value)}
                                                style={{ width: "100%" }}
                                                value={filter ? filter.value : ""}
                                            >
                                                <option value="all">Todo</option>
                                                <option value="pdf">PDF</option>
                                                <option value="jpg">JPG</option>
                                                <option value="png">PNG</option>
                                                <option value="csv">CSV</option>
                                            </select>,
                                        //filterable: true,
                                    },
                                    {
                                        id: 'date',
                                        Header: "Fecha",
                                        accessor: (d) => new Date(d.date).toLocaleDateString(props.locale, { timeZone: props.timeZone, hour12: false }) || '-',
                                        //filterable: true,
                                        Filter: ({ filter, onChange }) => (<DateRangeFilter applyFilter={(e) => { applyEndDateFilter(e); onChange(e); }}
                                            selectedEndDate={end_time.endDate}
                                            selectedStartDate={end_time.startDate}
                                            filter={filter} onChange={onChange}
                                            ref={end_timePick} />)
                                    },
                                    {
                                        id: '_id',
                                        Header: '',
                                        filterable: false,
                                        className: 'actions-cell',
                                        accessor: (d) => (
                                            (d.status !== 'Cancelled' && d.status !== 'Completed') ?
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="btn btn-xs btn-circle btn-default custom-dropdown-toggle">
                                                        <i className="ico fas fa-ellipsis-v text-dark" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={() => window.open(d.url, '_blank')}>Ver</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => handleDeleteFile(d.url, id)}>Eliminar</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                : null
                                        ),
                                        width: 50
                                    }
                                ]}
                                pages={Math.ceil(user?.files?.length / 10) || 0}
                                defaultPageSize={10}
                                showPagination={true}
                                showPaginationTop={false}
                                showPaginationBottom={true}
                                showPageJump={true}
                                resizable={false}
                                sortable={true}
                                className="-striped -highlight"
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-xs-12">
                        <div className="box-content card">
                            <div className='box-title d-flex justify-content-between'>
                                <h4 className="box-title"><i className="fa fa-star-half-alt ico"></i> Expensas</h4>
                                <button type="button" className="btn btn-success btn-md text-white" onClick={() => setExpensesModal(true)} >
                                    <i color={'white'} size={20} className='fa-solid fa-file-circle-plus' />
                                </button>
                            </div>
                            <ReactTable
                                data={expenses}
                                columns={[
                                    {
                                        id: 'amount_usd',
                                        Header: "Monto (USD)",
                                        accessor: d => d.amount_usd,
                                        filterable: true
                                    },
                                    {
                                        id: 'amount_crd',
                                        Header: "Monto (CRD)",
                                        accessor: d => d.amount_crd,
                                        filterable: true
                                    },
                                    {
                                        id: 'paid',
                                        Header: 'Pago',
                                        accessor: (d) => d.paid ? <i className="fas fa-check text-success"></i> : <i className="fas fa-times text-danger"></i>,
                                        filterable: true
                                    },
                                    {
                                        id: 'credits_paid',
                                        Header: 'Creditos pagados',
                                        accessor: d => d.crd_paid,
                                        filterable: true
                                    },
                                    {
                                        id: 'usd_paid',
                                        Header: 'USD pagados',
                                        accessor: d => d.usd_paid,
                                        filterable: true
                                    },
                                    {
                                        id: 'unpaid_balance_usd',
                                        Header: 'Deuda (USD)',
                                        accessor: d => d.unpaid_balance_usd,
                                        filterable: true
                                    },
                                    {
                                        id: 'currency',
                                        Header: "Moneda",
                                        accessor: d => d.currency,
                                        filterable: true,
                                        Filter: ({ filter, onChange }) =>
                                            <select
                                                onChange={event => onChange(event.target.value)}
                                                style={{ width: "100%" }}
                                                value={filter ? filter.value : ""}
                                            >
                                                <option value="all">Todo</option>
                                                <option value="USD">USD</option>
                                                <option value="CRD">CRD</option>
                                                <option value="ARS">ARS</option>
                                            </select>,
                                    },
                                    {
                                        id: 'description',
                                        Header: "Descripción",
                                        accessor: d => d.description,
                                        filterable: true,
                                    },
                                ]}
                                pages={expensesPages}
                                defaultPageSize={10}
                                onFetchData={fetchExpenses}
                                logading={loadingExpenses}
                                showPagination={true}
                                showPaginationTop={false}
                                showPaginationBottom={true}
                                showPageJump={true}
                                resizable={true}
                                manual
                                sortable={true}
                                ref={expensesRef}
                                className="-striped -highlight"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <AddUserFile
                user={id}
                modal={modal}
                setModal={setModal}
                updateUserFiles={updateUserFiles}
            />
            <AddUserImage
                user={id}
                modal={imageModal}
                setModal={setImageModal}
                updateUser={updateUserImage}
            />
            <AddUserExpense
                user={id}
                modal={expensesModal}
                setModal={setExpensesModal}
                onEnd={expensesRef?.current?.fireFetchData}
            />
        </div >
    );
};

const mapStateToProps = (state) => {
    return ({ timeZone: state.auth.user.timezone, locale: state.auth.user.locale, brand: state.brand });
};

const mapDispatchToProps = dispatch => ({
    setLoading: () => dispatch({ type: 'LOADING' }),
    setLoaded: () => dispatch({ type: 'LOADED' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(User);
