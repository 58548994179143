import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import logo from '../images/isologo-negative.png';
import $ from 'jquery';

const Sidebar = (props) => {
    useEffect(() => {
        $(".js__accordion").each(function() {
            var selector = $(this);
            selector.find(".js__control").on("click", function(event) {
                event.preventDefault();
                if ($(this).parent().hasClass("active")) {
                    $(this).parent().removeClass("active");
                    $(this).next().stop().slideUp(400);
                } else {
                    selector.find(".active").children(".js__content").stop().slideUp(400);
                    selector.find(".active").removeClass("active");
                    $(this).parent().addClass("active");
                    $(this).next(".js__content").slideDown(400);
                }
            });
        });

        $(".js__menu_mobile").on("click", function() {
            $("html").toggleClass("menu-active");
            $(window).trigger("resize");
        });

        $(".js__menu_close").on("click", function() {
            $("html").removeClass("menu-active");
        });

        $("body").on("click", function(event) {
            if ($("html.menu-active").length && $(window).width() < 800) {
                var selector = $(event.target);
                if (!(selector.hasClass("main-menu") || selector.hasClass("js__menu_mobile") || selector.parents(".main-menu").length || selector.parents(".js__menu_mobile").length)) {
                    $("html").removeClass("menu-active");
                }
            }
        });
    }, []);

    return (
        <section>
            <div className="main-menu">
                <header className="header">
                    <NavLink to='/' className="logo"><img src={logo} alt="CarbonBase" /></NavLink>
                    <button type="button" className="button-close fa fa-times js__menu_close"></button>
                    <div className="user">
                        <span className={`avatar sidebar-profile ${(!props.user.profile_pic || !props.user.profile_pic[0]) && 'bg-gradient-warning d-flex'}`} style={{ backgroundImage: (props.user.profile_pic && props.user.profile_pic[0]) && `url(${props.user.profile_pic[0]})` }}>
                            {
                                (!props.user.profile_pic || !props.user.profile_pic[0])
                                &&
                                <div className="admin-pic-placeholder">
                                    <span className="initials justify-content-center align-self-center">{props?.user?.first_name?.split(',').map(n => n[0].toUpperCase())}{props?.user?.last_name.split(',').map(n => n[0].toUpperCase())}</span>
                                </div>
                            }
                            <span className="status online"></span>
                        </span>
                        <h5 className="name"><span>{props.user.name}</span></h5>
                    </div>
                </header>
                <div className="content">
                    <div className="navigation">
                        <h5 className="title">Operaciones</h5>
                        <ul className="menu js__accordion">
                            <li>
                                <NavLink to="/" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-chart-line"></i><span>Dashboard</span></NavLink>
                            </li>
                            <li>
                                <NavLink to="/negative-balance" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fa-solid fa-user-minus"></i><span>Saldos negativos</span></NavLink>
                            </li>
                            <li>
                                <NavLink to="/movements" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fa-solid fa-shuffle"></i><span>Próximos movimientos</span></NavLink>
                            </li>
                            <li>
                                <NavLink to="/bookings" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-calendar-day"></i><span>Reservas</span></NavLink>
                            </li>
                            <li>
                                <NavLink to="/transactions" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-receipt"></i><span>Transacciones</span></NavLink>
                            </li>
                        </ul>
                        <h5 className="title">Gestión</h5>
                        <ul className="menu js__accordion">
                            <li>
                                <NavLink to="/assets" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-car"></i><span>Activos</span></NavLink>
                            </li>
                            <li>
                                <NavLink to="/users" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fa fa-user-friends"></i><span>Usuarios</span></NavLink>
                            </li>
                            <li>
                                <NavLink to="/plans" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-tags"></i><span>Planes</span></NavLink>
                            </li>
                        </ul>
                        <h5 className="title">Comunicación</h5>
                        <ul className="menu js__accordion">
                            <li>
                                {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                                <a className="parent-item js__control" href="#"><i className="menu-icon fas fa-sticky-note"></i><span>Contenidos</span><span className="menu-arrow fa fa-angle-down"></span></a>
                                <ul className="sub-menu js__content">
                                    <NavLink to="/contents">Activos</NavLink>
                                    <NavLink to="/contents/archived">Archivados</NavLink>
                                </ul>
                            </li>
                            <li>
                                <NavLink to="/notifications" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-bell"></i><span>Notificaciones</span></NavLink>
                            </li>
                            <li>
                                <NavLink to="/audiences" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-user-tag"></i><span>Audiencias</span></NavLink>
                            </li>
                        </ul>
                        <h5 className="title">Administración</h5>
                        <ul className="menu js__accordion">
                            <li>
                                <NavLink to="/admins" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-users-cog"></i><span>Administradores</span></NavLink>
                            </li>
                            <li>
                                <NavLink to="/app-assets" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-images"></i><span>Imágenes de Plataforma</span></NavLink>
                            </li>
                            <li>
                                <NavLink to="/timetable" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-clock"></i><span>Horarios</span></NavLink>
                            </li>
                            <li>
                                <NavLink to="/holidays" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-calendar-times"></i><span>Feriados</span></NavLink>
                            </li>
                            <li>
                                <NavLink to="/reminders" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-clock"></i><span>Recordatorios</span></NavLink>
                            </li>
                            <li>
                                <NavLink to="/settings" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-sliders-h"></i><span>Configuración</span></NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = state => ({ user: state.auth.user, brand: state.brand });

export default connect(mapStateToProps)(Sidebar);
